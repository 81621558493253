<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Autoplay } from 'swiper/modules'
import type { Swiper as SwiperType } from 'swiper'
import { AxiosError } from 'axios'
import type {
  CreatorHomeTabType,
  NovaBoxCreatorTopHeaderEmits,
  NovaBoxCreatorTopHeaderProps,
} from './NovaBoxCreatorTopHeader.types'
import { BlockingTarget, LayerType } from '@store'
import type { TabData } from '@components/NovaTab/NovaTab.types'
import type { HomeActionProps } from '@components/NovaBoxHomeAction/NovaBoxHomeAction.types'
import { type ShowCreatorNoticeDetailModalParams } from '@components/NovaBoxCreatorNoticeListItem/NovaBoxCreatorNoticeListItem.types'
import { useReCaptcha } from '@composables/useRechaptcha'
const emit = defineEmits<NovaBoxCreatorTopHeaderEmits>()
const props = withDefaults(defineProps<NovaBoxCreatorTopHeaderProps>(), {
  isShowTabMenu: true,
})
const { show: modalShow } = useModal()
const { tabs, profile } = toRefs(props)
const { gtEvent } = useGoogleTag()
const creatorStore = useCreatorStore()
const commStore = useCommStore()
// const mypageStore = useMyPageStore()
const layoutStore = useLayoutStore()
const appStore = useAppStore()
const { showFollowErrorMessage, showUnfollowErrorMessage } = useFollow()
const { userStore, showSignInDialog } = useMembershipProcess()
// const { show: modalShow, hide: modalHide } = useModal()
const { generateReCaptchaToken } = useReCaptcha()
const isLoading = ref(false)
const isOpen = ref(true)
const { t } = useI18n()
const creatorMyTaps = tabs.value.filter(
  (item) =>
    !(
      userStore.user?.userSn !== profile.value?.userSn &&
      (item.id === 'activity' ||
        item.id === 'reward' ||
        item.id === 'statistics' ||
        item.id === 'donation' ||
        item.id === 'collections')
    )
)
const isEqualUser = computed(
  () =>
    userStore.isSignIn &&
    creatorStore.profile?.userSn === userStore.user?.userSn
)
const creatorProfileDescription = computed(() =>
  (creatorStore.profile?.proflDc || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
)

const homeActionParams = computed(() => {
  const profile = creatorStore.profile
  const params: HomeActionProps | null = profile
    ? {
        target: BlockingTarget.CREATOR,
        userSn: profile.userSn,
        userNcnm: profile.userNcnm,
        isDonation: profile.prmbrshSbscrbAt === 'Y',
        blockingAt: profile.blockingAt,
        followerAt: profile.followerAt,
      }
    : null
  return params
})
const onChangeTab = (tabId: TabData<CreatorHomeTabType>) => {
  emit('onChangeTab', tabId.id)
}

const handleOnToggleFollow = async () => {
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }
  const doToggleFollow = async (
    toggleAction: () => Promise<void>,
    type: 'follow' | 'unfollow'
  ) => {
    try {
      await toggleAction()

      if (creatorStore.profile) {
        switch (type) {
          case 'follow':
            creatorStore.profile.followerCount += 1
            break
          case 'unfollow':
            creatorStore.profile.followerCount -= 1
            break
          default:
            break
        }
      }
    } catch {}
  }
  creatorStore.profile?.followerYn === 'N'
    ? await doToggleFollow(() => handleOnFollow(), 'follow')
    : await doToggleFollow(() => handleOnUnFollow(), 'unfollow')
}

const handleOnFollow = async () => {
  try {
    isLoading.value = true

    await useSleeper()
    // const { generateReCaptchaToken } = useReCaptcha()
    const recaptchaToken = await generateReCaptchaToken('FOLLOW')
    await commStore.reqFollow({
      flwUserSn: creatorStore.profile!.userSn,
      recaptchaToken,
    })
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '팔로우',
      eventLabel: t('follow'),
      eventSlot: '팔로우 하기',
      eventI18nAddr: useKoreanTranslation('follow'),
      eventComponent: 'Button',
      flwUserSn: creatorStore.profile!.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showFollowErrorMessage(err, creatorStore.profile?.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

const handleOnUnFollow = async () => {
  try {
    isLoading.value = true

    await useSleeper()
    await commStore.reqUnfollow({
      flwUserSn: creatorStore.profile!.userSn,
    })
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '언팔로우',
      eventLabel: t('following'),
      eventSlot: '언팔로우 하기',
      eventI18nAddr: useKoreanTranslation('following'),
      eventComponent: 'Button',
      flwUserSn: creatorStore.profile!.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showUnfollowErrorMessage(err, creatorStore.profile?.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

// 기부하기
const handleOnDonate = async () => {
  await useDonate({
    userSn: creatorStore.profile!.userSn,
    userNcnm: creatorStore.profile!.userNcnm,
    userProflUrl: creatorStore.profile!.userProflUrl,
  })
}

const profileData = reactive({
  userSn: creatorStore.profile!.userSn,
  followerCount: creatorStore.profile?.followerCount,
  followingCount: creatorStore.profile?.followingCount,
  coins:
    userStore.user?.userSn === creatorStore.profile?.userSn
      ? creatorStore.profile?.rwardSmLM
      : undefined,
  profile: creatorStore.profile,
})

const swiperOptions = computed(() => ({
  watchOverflow: true,
  loop:
    creatorStore.profile?.notices && creatorStore.profile?.notices.length > 1,
  loopAdditionalSlides: 1,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  modules:
    creatorStore.profile?.notices && creatorStore.profile?.notices.length > 1
      ? [Pagination, Autoplay]
      : [],
}))
const swiper = ref<SwiperType | null>(null)
const totalSlides = ref(0)

const onSwiper = (swiperInstance: SwiperType) => {
  swiper.value = swiperInstance
  totalSlides.value = swiperInstance.slides.length
}

const openIntroduceModal = () => {
  modalShow(modalsName.MODAL_CREATOR_PROFILE, {
    creatorProfile: creatorStore.profile,
  })
}

const currentIndex = ref(0)
const openNoticeDetailModal = () => {
  modalShow(modalsName.MODAL_CREATOR_NOTICE_DETAIL, {
    creatorNotice: creatorStore.profile?.notices[currentIndex.value],
  } as ShowCreatorNoticeDetailModalParams)
}

const onSlideChange = () => {
  if (swiper.value) {
    currentIndex.value = swiper.value.realIndex
  }
}

onUnmounted(() => {
  layoutStore['layer:close'](LayerType.USER_PROFILE_EDIT_PANEL)
})

const isNotice = computed(() => {
  return (
    creatorStore.profile?.notices && creatorStore.profile?.notices.length > 0
  )
})
</script>

<template>
  <div :class="['creator-top', { on: isNotice }]">
    <div v-if="isNotice" class="box-articles">
      <div class="box-contents" @click="openNoticeDetailModal">
        <div class="box-contents-notice">
          <NovaIcon class="icon" :icon="{ type: 'outline', name: 'speaker' }" />
          <Swiper
            v-bind="swiperOptions"
            :pagination="{
              clickable: true,
              el: '.swiper-pagination',
              type: 'custom',
            }"
            :direction="'vertical'"
            @swiper="onSwiper"
            @slide-change-transition-end="onSlideChange"
          >
            <SwiperSlide
              v-for="notice in creatorStore.profile?.notices"
              :key="notice.crtrNttSn"
            >
              <p v-dompurify-html="notice.crtrNttSj" class="on" />
            </SwiperSlide>
          </Swiper>
        </div>
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'chev-right' }"
          :size="16"
          class="btn-more"
        />
      </div>
    </div>
    <div :class="['top-section', { off: !isOpen }]">
      <div>
        <NovaImageContainer
          :key="creatorStore.profile?.backgroundImage"
          :image-url="creatorStore.profile?.backgroundImage"
          :ratio="'5:1'"
          :empty-type="'none'"
          class="top-section-bg"
        />
      </div>

      <div class="profile-wrap">
        <div class="option-tool">
          <span v-if="!isOpen" class="title">
            {{ t('creatorMyPage.profile.title') }}
          </span>
        </div>
        <div v-if="isOpen" class="profile-area">
          <div class="profile-box">
            <NovaBoxCreatorPortrait
              v-if="creatorStore.profile"
              :block-status="blockStatus"
              :profile="creatorStore.profile"
            />

            <NovaCreatorGradeBadge
              :creator-grade="creatorStore.profile!.crtrSpclGradCode"
              :creator-organization="creatorStore.profile!.crtrOrgnztTyCode"
              :size="'lg'"
              :tooltip-position="'top'"
              class="creator-badge"
            />
          </div>

          <div class="nickname-box">
            <p class="nickname">
              <span class="text">{{ creatorStore.profile?.userNcnm }}</span>
              <NovaIcon
                class="btn-edit"
                :icon="{ type: 'outline', name: 'chev-right' }"
                :size="18"
                @click="openIntroduceModal"
              />
            </p>
          </div>

          <!--        한줄 소개-->
          <div class="content-box">
            <span>
              <span v-dompurify-html="creatorProfileDescription" class="text" />
            </span>
          </div>

          <ClientOnly>
            <div v-if="!isShowBlockMessage" class="subscribe-box">
              <NovaBoxUserUnitSummery
                :user-sn="profileData?.userSn"
                :followers="profile?.followerCount || 0"
                :followings="profile?.followingCount || 0"
                :coins="profileData?.coins"
                :is-equal-user="isEqualUser"
                :creator="profileData?.profile"
              />

              <div
                v-if="userStore.user?.userSn !== creatorStore.profile?.userSn"
                class="btns-box"
              >
                <NovaButtonText
                  :on="creatorStore.profile?.followerYn === 'Y'"
                  :label="
                    t(
                      creatorStore.profile?.followerYn === 'N'
                        ? 'follow'
                        : 'following'
                    )
                  "
                  :responsive-size="{ desktop: 40, mobile: 32 }"
                  :loading="isLoading"
                  :class="
                    creatorStore.profile?.followerYn === 'Y'
                      ? 'btn-creator-unfollow'
                      : 'btn-creator-follow'
                  "
                  @click.stop="handleOnToggleFollow"
                />
                <!-- TODO: 앱 버전 릴리즈를 위해 기능 숨김 -->
                <!--              <NovaButtonText
                v-if="
                  !(
                    userStore.user &&
                    userStore.user.userSn === creatorStore.profile?.userSn
                  ) && !(appStore.isApp || appStore.envMode === 'production')
                "
                :label="
                  t(
                    `subscribe.actions.${
                      creatorStore.profile?.prmbrshSbscrbAt === 'Y'
                        ? 'supporting'
                        : 'support'
                    }`,
                    {
                      subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                    }
                  )
                "
                :responsive-size="{ desktop: 40, mobile: 32 }"
                :class="
                  creatorStore.profile?.prmbrshSbscrbAt === 'Y'
                    ? 'btn-creator-unfollow'
                    : 'btn-creator-follow'
                "
                @click.stop="handleOnSubscribe"
              />-->

                <!-- TODO: 앱 버전 릴리즈를 위해 기능 숨김 -->
                <!-- 노바+ 후원하기가 아닌 일반 후원하기 -->
                <!-- 원래 조건: v-if="!appStore.isApp && appStore.envMode === 'production'" -->
                <NovaButtonText
                  v-if="
                    !appStore.isApp &&
                    creatorStore.profile?.donationAvailableAt === 'Y'
                  "
                  :label="$t('donate')"
                  :responsive-size="{ desktop: 40, mobile: 32 }"
                  :class="'btn-creator-follow'"
                  @click.stop="handleOnDonate"
                />
              </div>
            </div>
          </ClientOnly>
        </div>
      </div>

      <NovaBoxHomeAction
        v-if="homeActionParams"
        :source="homeActionParams"
        :is-equal-user="isEqualUser"
        class="home-action"
      />

      <!-- 사용자 차단 안내 -->
      <slot name="blockContents" />

      <div v-if="isShowTabMenu" class="menu-box">
        <NovaTab
          :tab-value="creatorMyTaps"
          :init-tab="initTab"
          :align="'center'"
          :horizon-padding="20"
          @on-change-tab="onChangeTab"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.creator-top {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 42px;
  &.on {
    padding-top: 16px;
  }
  @include mobile {
    gap: 8px;
    padding-top: 20px;
    &.on {
      padding-top: 8px;
    }
  }
}

.top-section {
  flex-shrink: 0;
  position: relative;
  padding: 13px 20px 0;
  border-radius: 16px;
  background-color: $color-white;

  :deep(.top-section-bg) {
    &.is-load-success {
      background: inherit;
    }
  }

  .top-section-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 245px;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(
      97.77deg,
      $color-secondary-blue-light-80 -5.67%,
      $color-highlight 126.11%
    );
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 245px;
      border-radius: 10px 10px 0 0;
    }

    &::after {
      background: hex-to-rgba($color-bg-custom-7, 0.3);
    }
  }

  &.off {
    &::before,
    &::after {
      height: 50px;
    }

    .profile-wrap {
      max-height: 100px;
    }

    .option-tool {
      justify-content: space-between;
    }
  }

  > .badge-live {
    position: absolute;
    top: calc(245px + 19px);
    right: 32px;
    z-index: 11;
    //z-index: 30;

    @include mobile {
      top: calc(245px + 10px);
      right: 18px;
    }
  }
}

.profile-wrap {
  position: relative;
  z-index: 10;
  @include transition(max-height 0.3s);
}

.option-tool {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color-white;
  height: 20px;

  .title {
    @include text-style($text-heading2-bold);
  }

  .option-button {
    display: flex;
    gap: 8px;
    position: relative;
  }

  .page-setting {
    bottom: auto;
    top: 100%;
    right: 0;
    transform: translateY(10px);
  }
}

.menu-box {
  border-top: 1px solid hex-to-rgba($color-black, 0.06);
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
}

.top-section {
  .profile-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 360px;
    padding-top: 145px;
    padding-bottom: 30px;
    color: $color-text-4;
    opacity: 1;
    @include transition(opacity 0.15s ease-in-out);

    .profile-box {
      position: relative;
      padding: 0 12px;

      .btn-box,
      .btn-delete {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .btn-delete {
        color: $color-white;
        top: 0;
        bottom: auto;
      }

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;

        .input-file {
          width: 0;
          height: 0;
          visibility: hidden;
        }
      }

      .creator-badge {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .nickname-box {
      margin-top: 16px;
      width: 80%;
      @include mobile {
        width: 100%;
      }
      .nickname {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
        .text {
          font-weight: 700;
          font-size: 20px;
          color: #111111;
          word-break: break-all;
        }
        .btn-edit {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          cursor: pointer;
          color: #111111;
          font-weight: 700;
        }
      }
    }

    .content-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3px;
      width: 80%;
      @include mobile {
        width: 100%;
      }
      .text {
        font-size: 16px;
        display: inline;
        color: $color-text-2;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }

      .btn-edit {
        margin-left: 8px;
      }
    }
    .edit-box {
      width: 100%;
      .action-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 10px;
      }
    }

    .subscribe-box {
      display: flex;
      flex-direction: column;
      margin: 20px 0 0 0;

      .subscribs {
        display: flex;
        align-items: center;
        gap: 24px;
        p {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          gap: 6px;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.3px;
            color: $color-99;
          }
          strong {
            display: block;
            position: relative;
            font-weight: bold;
            font-size: 24px;
            line-height: 100%;
            letter-spacing: -0.3px;
            color: #3f4354;
            &:before {
              display: block;
              content: '';
              width: 110%;
              min-width: 42px;
              height: 13px;
              background: #347ae2;
              opacity: 0.1;
              position: absolute;
              left: 50%;
              bottom: -5px;
              transform: translateX(-50%);
            }
          }
        }
      }
      .btns-box {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin: 28px 0 0 0;

        .btn-creator-follow {
          min-width: 110px;
          height: 30px;
          padding: 0 20px;
          background: $color-primary-blue-dark;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.3px;
          border-radius: 5px;
        }

        .btn-creator-unfollow {
          min-width: 110px;
          height: 30px;
          padding: 0 20px;
          background: $color-bg-1;
          color: #3f4354;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.3px;
          border-radius: 5px;
        }
      }
    }
  }
  .portrait-container {
    height: 122px;
    width: 122px;
  }
  .home-action {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 12;
    border-radius: 10px;
    background: $color-white-80p;
  }
}

.page-setting {
  z-index: 100;
}
.top-section {
  :deep(.block-wrap) {
    padding-bottom: 20px;
    min-height: auto;
  }
}

@include mobile {
  .menu-box {
    :deep(.tabs .swiper-wrapper) {
      justify-content: flex-start !important;
    }
  }
}

.box-articles {
  width: 100%;
  position: relative;
  z-index: 2;
  @include mobile {
    padding: 0 8px;
  }
  .box-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 16px !important;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px #00000014;

    .box-contents-notice {
      display: flex;
      align-items: center;
      gap: 10px;
      :deep(.swiper) {
        height: 24px;
        .swiper-wrapper {
          .swiper-slide {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            p {
              @include text-style($text-body-14-medium);
            }
          }
        }
      }
    }
    .btn-more {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
