<script setup lang="ts">
import type { NovaBoxCreatorPortraitProps } from './NovaBoxCreatorPortrait.types'

const props = defineProps<NovaBoxCreatorPortraitProps>()

const userStore = useUserStore()
const mypageStore = useMyPageStore()
const enterLiveLoading = ref(false)
const isEqualUser = computed(
  () => props.profile.userSn === userStore.user?.userSn
)

// 라이브 진입 제거하면서 주석처리
// const handleOnEnterLiveByGuest = async () => {
//   console.log('다른 사람 라이브 진입')
//   // CHECK enter live available 1.. 로딩중 재요청 리턴
//   if (enterLiveLoading.value) return
//
//   // CHECK enter live available 2. 크리에이터 및 현재 사용자간 차단상태가 존재하면 리턴
//   if (props.blockStatus !== BlockStatus.NO_BLOCKING) return
//
//   // CHECK enter live available 2. 라이브가 OnAir 상태 및 sendbird live sn 확인
//   if (props.profile.liveOnAirAt !== 'Y' || !props.profile.sndbrdLiveSn) return
//
//   await enterLive({
//     mode:
//       props.profile.userSn === userStore.user?.userSn
//         ? LiveMode.HOST
//         : LiveMode.GUEST,
//     checkPermissionType: 'sndbrdLiveSn',
//     sndbrdLiveSn: props.profile.sndbrdLiveSn,
//     liveOnAirAt: props.profile.liveOnAirAt,
//     onLoading: (loading: boolean) => {
//       enterLiveLoading.value = loading
//     },
//   })
// }
</script>

<template>
  <div :class="['portrait-wrap', { 'is-loading': enterLiveLoading }]">
    <!-- 라이브 진입 제거 하면서 코드 수정 -->
    <!-- 이전 코드 :stroke="true", :highlight-stroke="
      blockStatus === BlockStatus.NO_BLOCKING && profile.liveOnAirAt === 'Y'
    " -->
    <NovaPortraitContainer
      class="profile"
      :size="'xl'"
      :stroke="false"
      :image-url="
        isEqualUser ? mypageStore.profile?.userProflUrl : profile.userProflUrl
      "
    />

    <!--    <NovaBadgeLiveAction-->
    <!--      v-if="-->
    <!--        blockStatus === BlockStatus.NO_BLOCKING &&-->
    <!--        profile.liveOnAirAt === 'Y' &&-->
    <!--        !isEqualUser-->
    <!--      "-->
    <!--      :available-click="false"-->
    <!--      :on-air="true"-->
    <!--      :tiny="true"-->
    <!--    />-->

    <div class="loading-indicator-wrap">
      <NovaLoadingIndicator
        v-if="enterLiveLoading"
        :bg-bright="'dark'"
        :fill="false"
        :size="80"
        class="loading"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.portrait-wrap {
  position: relative;
  //cursor: pointer;

  > .badge-live {
    position: absolute;
    top: 18px;
    right: -14px;
  }

  .loading-indicator-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: hex-to-rgba($color-white, 0);
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
  }

  &.is-loading {
    .loading-indicator-wrap {
      background-color: hex-to-rgba($color-white, 0.6);
    }
  }
}
</style>
